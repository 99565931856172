import { graphql, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import styled from "styled-components";
import emailjs from "emailjs-com";
import { formatFacilityTime } from "@utils/timeFormatter";
import {
  fontWeight,
  color,
  fontSize,
  font,
  screenBreakPoint,
} from "@styles/styleVariables";
import { ISContactFormProps } from "@interface/styledComponentInterface";
import { convertHexToRGBA } from "@utils/convertHexToRGBA";
import { BrandButton } from "@components/reusables/brandButton";
import { ButtonEnum } from "src/enums/buttonsEnum";

export const ContactContent = () => {
  const { contactMessage, hours, address } = useStaticQuery(graphql`
    query contactQuery {
      contactMessage: strapiContactInformation {
        phone
        emailSentMessage
        contactPageSlogan
      }
      address: strapiFacilityAddress {
        facilityName
        streetAddress
        city
        province
        postalCode
      }
    }
  `);

  const serviceId = process.env.GATSBY_EMAILJS_SERVICE_ID ?? "";
  const templateId = process.env.GATSBY_EMAILJS_TEMPLATE_ID ?? "";
  const userId = process.env.GATSBY_EMAILJS_USER_ID ?? "";
  const publicKey = process.env.GATSBY_PUBLIC_KEY ?? "";
  const [sent, setSent] = useState(false);
  const [visitDojang, setVisitDojang] = useState(false);

  function sendEmail(e: any) {
    e.preventDefault();
    emailjs.sendForm(serviceId, templateId, e.target, userId).then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
    e.target.reset();
    setSent(true);
  }

  return (
    <SContact className="content-wrapper" sent={sent}>
      <div className="contact-page-texts">
        <div className="contact-page-slogan">
          <h3>
            {contactMessage?.contactPageSlogan ?? ""}{" "}
            {contactMessage?.phone ?? ""}
          </h3>
        </div>
        <div className="contact-page-facility-info">
          <div className="contact-page-address">
            <h3>{address?.facilityName?.toUpperCase() ?? ""} LOCATION:</h3>
            <p>
              {address?.streetAddress ?? ""}, {address?.city ?? ""}
            </p>
            <p>
              {address?.province ?? ""} {address?.postalCode ?? ""}
            </p>
          </div>
        </div>
      </div>
      <div className="contact-form-wrapper" id="contactform">
        <form className="contact-form" onSubmit={(e) => sendEmail(e)}>
          <div className="row name-row">
            <label htmlFor="name">Your Name</label>
            <input
              id="name"
              type="text"
              name="name"
              required
              placeholder="your name"
            ></input>
          </div>
          <div className="row email-row">
            <label htmlFor="email">Your Email</label>
            <input
              id="email"
              type="email"
              name="email"
              required
              placeholder="your email"
            ></input>
          </div>
          <div className="row phone-row">
            <label htmlFor="telephone">Your Phone Number</label>
            <input
              id="telephone"
              type="tel"
              name="telephone"
              placeholder="(optional)"
            ></input>
          </div>
          <div className="row subject-row">
            <label htmlFor="subject">Subject</label>
            <input
              id="subject"
              type="text"
              name="subject"
              required
              placeholder="(e.g. Free Trial)"
            ></input>
          </div>
          <div className="row message-row">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              rows={5}
              placeholder="your message"
              required
            ></textarea>
          </div>
          <div className="visit-dojang-row">
            <p>Want to Visit our Dojang?</p>
            <input
              id="visitDojang"
              name="visitDojang"
              checked={visitDojang}
              type="checkbox"
              onClick={() => setVisitDojang(!visitDojang)}
            ></input>
          </div>
          {visitDojang && (
            <div className="visit-dojang-info">
              <div className="row student-name-row">
                <label htmlFor="studentName">Student Name</label>
                <input
                  id="studentName"
                  type="text"
                  name="studentName"
                  required={visitDojang}
                  placeholder="Student Name"
                  disabled={!visitDojang}
                ></input>
              </div>
              <div className="row parent-name-row">
                <label htmlFor="parentName">Parent Name</label>
                <input
                  id="parentName"
                  type="text"
                  name="parentName"
                  placeholder="Parent Name (if required)"
                  disabled={!visitDojang}
                ></input>
              </div>
              <div className="row datetime-visit-row">
                <label htmlFor="visitTime">
                  Preferred Date and Time to Visit
                </label>
                <input
                  id="visitTime"
                  type="text"
                  name="visitTime"
                  placeholder="e.g. Mon, Jan 29 at 5pm"
                  disabled={!visitDojang}
                ></input>
              </div>
              <div className="row extra-information-row">
                <label htmlFor="message">Extra Information</label>
                <textarea
                  disabled={!visitDojang}
                  id="extraInformation"
                  name="extraInformation"
                  rows={5}
                  placeholder="Other information you'd like us to know!"
                ></textarea>
              </div>
            </div>
          )}
          <div className="row submit-row">
            <input
              type="submit"
              value="SEND MESSAGE"
              className="send-button"
            ></input>
          </div>
        </form>
        <div className={`form-submitted-message`}>
          <h3>{contactMessage.emailSentMessage}</h3>
          <div onClick={() => setSent(false)}>
            <BrandButton
              link="/contact"
              name="NEW MESSAGE"
              id="newmessage"
              category={ButtonEnum.Tertiary}
            ></BrandButton>
          </div>
        </div>
      </div>
    </SContact>
  );
};

const SContact = styled.div<ISContactFormProps>`
  min-height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: ${screenBreakPoint.sm}) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;

    .contact-page-texts {
      margin-top: 2rem;
      margin-right: 0rem;
    }
  }

  .contact-page-texts {
    flex: 1;
    margin-right: 1rem;
    h3 {
      color: black;
    }

    .contact-page-slogan {
      margin-bottom: 2rem;
    }

    .contact-page-facility-info {
      .contact-page-hours,
      .contact-page-address {
        margin-bottom: 2rem;
        h3 {
          border-bottom: solid 0.5rem ${color.sunglow};
          width: fit-content;
        }
      }
    }
  }

  .contact-form-wrapper {
    flex: 1;
    width: 100%;
    .form-submitted-message {
      display: ${(props) => (props.sent ? "block" : "none")};
    }

    h3 {
      color: black;
    }
    form {
      display: ${(props) => (props.sent ? "none" : "block")};
      width: 100%;

      input,
      textarea {
        width: 100%;
        padding: 1rem;
        font-size: ${fontSize.sm};
        background: ${convertHexToRGBA(color.sunglow, 0.1)};
        border: 1px solid ${color.black};
        margin-bottom: 2rem;
        resize: vertical;
        font-family: ${font.poppins};
      }

      .visit-dojang-row {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 2rem;
        p {
          margin-right: 2rem;
        }
        input {
          padding: 0rem;
          margin: 0rem;
          vertical-align: middle;
          width: fit-content;
        }
      }
      .send-button {
        font-weight: ${fontWeight.semiBold};
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s ease-in-out;
        border-radius: 10px;
        transition: all 0.1s ease-in-out;
        width: 100%;
        height: 100%;
        padding: 0.75rem 5rem;
        color: inherit;
        color: ${color.prussianBlue};
        background: ${color.sunglow};
        border: 0.25rem solid ${color.sunglow};
        cursor: pointer;
        &:visited {
          color: inherit;
        }
        &:hover,
        &:focus {
          border: 0.25rem solid ${color.oxfordBlue};
          background: ${color.culture};
          color: ${color.oxfordBlue};
        }
      }
    }
  }
`;
